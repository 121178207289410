
<!--能源设置--趋势组组---->
<template>
  <div id="trendChart" v-loading="loading" :element-loading-text="$t('alarm.a0')"> 

    <div class="header-title">
      {{ $t("energy.enedata537") }}
    </div>
    <div style="display:flex;width: 100%; height: 100%;">
      <div class="side">
        <div class="side-menu">
            <a-menu
              style="width: 100%;"
              mode="inline"
              :inline-collapsed="collapsed"
              :open-keys.sync="openKeys"
              @click="handleClick"
              v-model="selectedKeys"
            >
              <template v-for="item in rptGroupList">
                <a-menu-item v-if="!item.children" :key="item.no">
                  <a-icon type="pie-chart" />
                  <span>{{ item.text }}</span>
                </a-menu-item>
                <sub-menu v-else :key="item.no" :menu-info="item" :isdelete="false" />
              </template>
              
            </a-menu>
        </div>
          
      </div>
      <div class="main" ref="main" v-if="rptGroupId==0">
            <a-empty>
              <span slot="description"> {{ $t("energy.setting.reportGr.empty") }} </span>
            </a-empty>
      </div>
      <div class="main" ref="main" v-else> 
          <div style="width:100%;height：100%;">
              <div class="rptGroup"  style="">
                  <div class="rptGroupName" style="">
                    <span>{{ $t("energy.setting.reportGr.grlabel") }}：</span><span>{{rptGroup.reportGroupName}}</span>
                  </div>
              </div>
             <div class="rptGroupPoint">
                <a-table 
                  :columns="pointColumns" 
                  :data-source="signalPoints"   
                  :pagination=false          
                  size="middle"            
                  >
                    <span slot="items" slot-scope="record">                
                        <a-select :value="record.key" style="width: 300px" @change="handleSignalPointChange">
                          <a-select-option v-for="item in rptGroup.rptGroupList" :key="item.key" :title="  item.tagPoint  +'-'+   item.signalName ">
                            {{ item.tagPoint }} -  {{ item.signalName }}
                          </a-select-option>
                        </a-select>             
                    </span>
                </a-table> 
                <div style="padding:5px;">
                    <a-button class="editable-add-btn" @click="handleAdd" type="primary" style="margin-right:20px;">  {{$t('dataCompareTrend.a4')}}  </a-button>   
                    <span style="line-height:32px;color:#cccccc;">{{$t('energy.enedata542')}}</span>   
                </div>
                <a-table    
                  class="date-table"         
                  :columns="chooseDateColumns" 
                  :data-source="chooseDateList" 
                  :pagination="false"               
                  :customRow="click" 
                  size="middle"            
                  >
                  
                    <span  slot="date" slot-scope="record">
                      <a-date-picker  v-model="record.date" @change="handleDateChange" format="YYYY/MM/DD" />
                    </span >
                    
                    <span  slot="operation" slot-scope="text, record">
                      <a-popconfirm
                        v-if="chooseDateList.length"
                        :title="$t('dataCompareTrend.a5')"
                        @confirm="() => onDelete(record.key)"
                      >
                        <a href="javascript:;">{{$t('dataCompareTrend.a6')}}</a>
                      </a-popconfirm>
                    </span >              
                </a-table> 
                <div style="padding:5px;">
                <a-button type="primary" @click="showChart" :disabled="this.chooseDateList.length==0">{{$t('energy.search')}}</a-button>
                </div>
             </div>  
            
              <a-modal v-model="visible"
                  :title="dialogTitle"
                  :width="1260" 
                  @ok="showChart"
                  :ok-text="$t('energy.enedata128')"
                  :cancel-text="$t('video.viddata054')"
              >
                  <template>
                    <div v-loading="mloading" :element-loading-text="$t('alarm.a0')" style="width:100%;height:640px;">
                        <a-tabs type="card" v-model="tabKey" >
                          <a-tab-pane key="1" :tab="$t('energy.enedata139')" style="position: relative;">
                              <div id="eneTrendChartImg" ref="eneTrendChartImg" style="width:1180px;height:540px"></div>
                          </a-tab-pane>
                          <a-tab-pane key="2" :tab="$t('energy.enedata449')" style="position: relative;">
                              <a-table
                                  :columns="columns"
                                  :data-source="tableData"
                                  :pagination="pagination"
                                  :scroll="xscroll"
                                  bordered
                                  size="small">
                              </a-table>
                              <div class="btn-display button">
                                    <!-- CSV导出 -->
                                    <a-button type="primary" @click="csvOut" :disabled="this.chooseDateList.length==0">{{$t('energy.enedata042')}}</a-button>
                              </div>
                          </a-tab-pane> 
                        </a-tabs> 
                    </div> 
                  </template>
              </a-modal>
          </div>
          <div>
            <!-- <p>{{$t('energy.enedata540')}}</p> -->
             
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEneReportGrList , getEneReportGrDetail,getEnergySitesTrendData ,downloadEnergyCSVDataWithURLWithURL} from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import moment from "moment";
import { downloadUrl } from "../../../utils/utils";

export default {
  components: {
    'sub-menu': SubMenu,
  },
  data() {   
      return {
        loading:false,
        tabKey:'1',
        selectedKeys:[],
        rptGroupList:[],
        rptGroupId:0,
        rptGroup:{
            reportGroupName: "",
            reportGroupNo: 0,
            reportType: 1,
            rptGroupList: [],
        },
        Yscroll:{x:1200,y:400},
        xscroll:{x:1100,y:400},
        rootSubmenuKeys: ["0"],
        openKeys: ["0"],
        visible:false,
        dataChange:false,
        selChange:false,
        collapsed: false,
        modeno:19,
        lmKind:3,
        reportGroupType:4,
        showDialog:false,
        dialogTitle:this.$t('energy.setting.reportGr.trendGr.chartName'),// ' 时间设置趋势图',
        dialogTitle2:this.$t('energy.setting.reportGr.trendGr.chartName'),//'时间设置趋势图',
        selectedPointIds:[],
        trendParam:{
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
        },
        tableData:[],
        columns:[],
        pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = 1;
            this.pagination.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
          },
        },
        option:{
          title: {
            text: 'Step Line',
            textStyle: {
              fontSize: 18,
              color: "#7682ce",
              fontWeight: "bold"
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['模式', '温度']
          },
          grid: {
            left: '6%',
            right: '6%',
            bottom: '5%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: [
            {
              name: 'Flow(m^3/s)',
              type: 'value'
            },
            {
              name: 'Rainfall(mm)',
              nameLocation: 'end',
              alignTicks: true,
              type: 'value',
              inverse: false
            }
          ],
          series: [
            {
              name: '模式',
              type: 'line',
              step: 'middle',
              data: [1, 2, 2, 3, 1, 4, 2],
              yAxisIndex: 0,
            },
            {
              name: '温度',
              type: 'line',
              data: [20, 28, 27, 32, 21, 35, 28],
              yAxisIndex: 1,
            },
          ]
        },
        trendChart:undefined,
        mloading:false,
        pointColumns : [
          {
            title: this.$t('dataCompareTrend.a22'),
            width: 140,
            scopedSlots: { customRender: 'items' },
          },  
          {
            title: this.$t('dataCompareTrend.a23'),
            dataIndex: "kikiName",
            width: 100,
            ellipsis: true,
          },
          {
            title: this.$t('dataCompareTrend.a24'),
            dataIndex: "signalName",
            width: 140,
            ellipsis: true,
          },
          {
            title: this.$t('dataCompareTrend.a25'),
            dataIndex: "unit",
            width: 50,
            ellipsis: true,
          },
          {
            title: "TagPoint",
            dataIndex: "tagPoint",
            width: 80,
            ellipsis: true,
          },
        ],
        signalPoints:[],
        selectSignalPoint:undefined,
        chooseDateColumns:[
          {
            title: this.$t('dataCompareTrend.a18'),
            scopedSlots: { customRender: 'date' },
            width: 120,
            ellipsis: true,
          },  
          {
            title: this.$t('dataCompareTrend.a19'),
            dataIndex: "day",
            width: 80,
            ellipsis: true,
          },
          // {
          //   title: this.$t('dataCompareTrend.a20'),
          //   scopedSlots: { customRender: 'chart' },
          //   dataIndex: "chart",
          //   width: 120,
          //   ellipsis: true,
          // },
          {
            title: this.$t('dataCompareTrend.a21'),
            width: 80,
            scopedSlots: { customRender: 'operation' },
            ellipsis: true,
          },       
        ],
        chooseDateList:[],
        chooseDates:[],
      };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    this.selectedKeys=[];
    this.rptGroupList=[];
    this.rptGroupId=0;
    this.rptGroup={
        reportGroupName: "",
        reportGroupNo: 0,
        reportType: 1,
        rptGroupList: [],
    };
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadReportGrList(0);
    }
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  computed: {
    
  },
  watch: {
    openKeys(val) {
      //console.log('openKeys', val);
    },
    "$store.getters.clientId":{
      handler(val){
        //console.log(val);
        if(val!==""){
          this.loadReportGrList(0);
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods: {
     //日期修改响应
    handleDateChange(date, dateString) {
      console.log(date, dateString);
      const dataSource = [...this.chooseDateList];
      let chooseDateList = dataSource.filter(item => item.key == dateString);
      let len = chooseDateList.length;
      if(this.chooseDateInfo.key != dateString){
          if(len===0){

            if(date!=null&&date.day()!=null){
              this.chooseDateInfo.date = date;
              this.chooseDateInfo.day = date.format('ddd');
            }
            if(dateString!=null){
              this.chooseDateInfo.key = dateString;
              this.chooseDateInfo.dateString = dateString;
            }
            console.log('handleDateChange:',this.chooseDateInfo);
          }else{
            console.log("EXIT>>", this.chooseDateInfo);
            this.chooseDateInfo.date = new moment(this.chooseDateInfo.dateString);
            this.$message.error(this.$t('energy.enedata541'));//'所选日期已存在，请勿重复选择。');
          }
      }
    },
    click(record, index){
        return {
            on: {
               click: () => {
                    // console.log(record,index)
                    this.chooseDateInfo = record;
                    // console.log("row click1:",this.chooseDateList[index]);
                    console.log("row click2:",record);
               }
            }
        }
    },
    onDelete(key) {
      const dataSource = [...this.chooseDateList];
      console.log("choose chooseDateList:",this.chooseDateList);
      console.log("choose key:",key);
      this.chooseDateList = dataSource.filter(item => item.key != key);
    },
    handleAdd() {
      if(this.chooseDateList!=null&&this.chooseDateList.length<7){//限制最大6天
        let len = this.chooseDateList.length;
        let myDate = moment();
        if(len>0){
          myDate = new moment(this.chooseDateList[len-1].key);
        }
        // len = len*-1;
        myDate = myDate.add(-1,'day');
        let key = myDate.format('YYYY/MM/DD');
        let cclist = this.chooseDateList.filter(item => item.key == key);
        let clen = cclist.length;
        while(clen>0){
           myDate = myDate.add(-1,'day');
           key = myDate.format('YYYY/MM/DD');
           cclist = this.chooseDateList.filter(item => item.key == key);
           clen = cclist.length;
        }
        let newData = {
          key: myDate.format('YYYY/MM/DD'),
          day: myDate.format('ddd'),
          chart: "brokenLine",
          date: myDate,
          color: "#00AAFF",
          dateString: myDate.format('YYYY/MM/DD')
        };
        console.log('handleAdd:',newData);
        this.chooseDateList.push(newData);
      }else{
        //  this.$message.error('最多添加7个日期。');
         this.$message.error(this.$t('energy.enedata542'));
      }
    },
    handleSignalPointChange(e){

      this.signalPoints.length=0;
      this.rptGroup.rptGroupList.forEach(element => {
        if(e===element.key){
          this.selectSignalPoint = element;
        }
      });
      this.signalPoints.push(this.selectSignalPoint);      
      console.log('selectSignalPoint changed：',e, this.selectSignalPoint);
    },
    csvOut(){
        let points = [];
        console.log('selectSignalPoint',this.signalPoints);
        points.push(this.signalPoints[0].key);
        let dates  = [];
        this.chooseDateList.forEach(element => {
          dates.push(element.key);
        });
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId:   this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            points:points,
            dates
        };

        console.log('params',params);
        this.mloading = true;
        console.log('downloadEnergyCSVDataWithURLWithURL param',params);
        downloadEnergyCSVDataWithURL('bivale/energy/data/site/trend/normal/download',params)
        .then((res) => {
            let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata449')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",res);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.mloading = false;
            this.$message.error(this.$t('energy.enedata376'));
            console.log('downloadEnergyCSVDataWithURL',err);
        })
        .finally(()=>{
            this.mloading = false;
        });
    },
    getTrendData(){
        let points = [];
        console.log('selectSignalPoint',this.signalPoints);
        points.push(this.signalPoints[0].key);
        let dates  = [];
        this.chooseDateList.forEach(element => {
          dates.push(element.key);
        });
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId:   this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            points:points,
            dates
        };

        console.log('params',params);
        this.tabKey = '1';
        this.visible = true;
        this.mloading = true;
        getEnergySitesTrendData(params)
        .then((res) => {
            console.log("GetEnergySitesTrendData Result",res);

            this.mloading = false;

            if(res && res.errorCode == '00'){
                
                this.columns = res.data.columnList;
                this.tableData = res.data.bodyList;
                this.option.xAxis = res.data.chartData.xAxis;
                this.option.yAxis = res.data.chartData.yAxis;
                this.option.series = res.data.chartData.series;
                this.option.legend = res.data.chartData.legend;
                this.option.title.text  = res.data.chartData.title;
                this.dialogTitle = this.dialogTitle2 +"——"+ res.data.chartData.title;
                this.drawLine();
            } else {
                this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
            }
            
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },
      drawLine() {
         
          let resizeDiv = document.getElementById('eneTrendChartImg');
          console.log('resizeDiv',resizeDiv);
          if(resizeDiv){
           if(this.trendChart){
                this.trendChart.dispose();
            }
            this.trendChart = this.$echarts.init(document.getElementById('eneTrendChartImg'));
            this.trendChart.setOption(this.option);
            this.trendChart.resize();
          }
      },
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let width = this.$refs.main.clientWidth;
              let tableHeight = height - 130;
          }, 0);
      },
      handleClick(e) {
          this.rptGroupId = parseInt(e.key);
          if(parseInt(this.rptGroupId)>0){
            this.selChange = true;
          }
          this.selectedPointIds.length=0;
          this.loadReportGrDetail();
      },
      loadReportGrDetail() {
          if(parseInt(this.rptGroupId)>0){
              let params =  {
                  action:0,
                  clientId: this.$store.getters.clientId,
                  siteId: this.$route.query.id,
                  sitegrId: this.$store.getters.sitegrId,
                  tenantId: 0,
                  reportGroupType: this.reportGroupType,
                  reportGroupNo: this.rptGroupId,
              };
              console.log("loadMeasureDetail Params",params);
              this.loading = true;
              this.signalPoints = [];
              this.chooseDateList = [];
              getEneReportGrDetail(params)
              .then((res) => {
                  console.log("loadMeasureDetail Result",res);
                  this.loading = false;
                  if(res && res.errorCode == '00'){
                      this.rptGroup = res.data;
                      
                      this.signalPoints.push(res.data.rptGroupList[0]);
                  } else {
                      this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
                  }

              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
          }
      },
      loadReportGrList(action){
        let params =  {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportGroupType: this.reportGroupType,
        };
        console.log("loadReportGrList Params",params);
        this.loading = true;
        getEneReportGrList(params)
        .then((res) => {
            console.log("loadReportGrList Result",res);
            if(res && res.errorCode == '00'){
                this.rptGroupList = res.data.rptGroupList;
            } else {
                this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
            }     
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },
      showChart(){
        
          this.getTrendData();
      },


  }
};
</script>

<style scoped>
#trendChart {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}
div /deep/.ant-modal-body{
    padding: 5px 10px;    
}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #7585cd;
  margin-right: 20px;
}
.side {
  width: 280px;
  height: calc(100% - 24px);
  box-shadow: 0px 3px 8px #e7e6e6;
}
.side-menu{
  width:100%;
  max-height:  calc(100% - 50px);
  overflow: auto;
}
.main {
  width: calc(100% - 280px);
  height: calc(100% - 24px);
  padding: 5px;
  box-shadow: 0px 3px 8px #e7e6e6;
}
.mg-add{
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 22px;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin-top: 5px;
}
.mg-add:hover{
  color: #FFFFFF;
  background-color: #7682ce;
  border:1px solid #7682ce;
 
}
.rptGroup{display: flex; justify-content: flex-start;}
.rptGroupName{margin:3px;padding:3px;font-size:14px;line-height:24px;}
.rptGroupPoint{width: 100%;height: calc(100% - 100px);}
.btn-display{
  top:-65px;right: 3px;position:absolute;
}
.button{
  padding: 10px 0;
}
.header-box{
  padding:5px;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
}
.header-box button{
  text-align: right;
}

.header-column {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
}
.header-column span {
  font-size: 14px;
}

</style>
